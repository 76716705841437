import React from 'react'
import styles from './Footer.module.css'
import logo from '../../assets/logo2.svg'
import {ReactComponent as FacebookIcon } from '../../assets/icons8-facebook-48.svg'
import {ReactComponent as MailIcon } from '../../assets/Path 1104.svg'
import {ReactComponent as MobileIcon } from '../../assets/Path 1106.svg'
import {ReactComponent as LinkedinIcon } from '../../assets/linkedin.svg'
import {ReactComponent as InstgramIcon } from '../../assets/icons8-instagram-48.svg'
import PhoneNumberLink from '../Contact/phonenumber'
import EmailLink from '../Contact/maillink'
import { Link } from 'react-router-dom'

export default function Footer() {
  return <>
  <div className={styles.Footer}> 
  <div className='container'>
  <div className="row my-4">
  <div className="col-md-5 me-5">
    <div>
    <img src={logo} className='mb-4 logo' alt='ExecutionTech' />
      <div>
        <p>We are a software development and solutions provider specializing in designing and developing professional business solutions.</p>
      </div>
      <div>
    <Link to='https://www.facebook.com/profile.php?id=61552161909684&mibextid=ZbWKwL' target="_blank"><FacebookIcon width={45} /></Link>
    <Link to='https://www.linkedin.com/company/execution-tech/' target="_blank" ><LinkedinIcon width={45} /></Link>
    <Link to='https://www.instagram.com/executiontechnology/' target="_blank" ><InstgramIcon width={45} /></Link>

      
    </div>
    </div>
  </div>

  <div className="col-md-5 mt-2">
    <div>
      <div>
        <h5>Contact Details</h5>
       

      <div className='mt-4'>
      <span className='me-2'><MailIcon width={25} height={25} /></span>
      <EmailLink email="contact@execution-tech.com" />
      </div>
    
      <div className='mt-2'>
      <span className='me-2'> <MobileIcon width={25} height={25} /></span>
      <PhoneNumberLink isDone={true}  phoneNumber={'+201003718640'}/>  / <PhoneNumberLink   isDone={true}  phoneNumber={'+201097709762'}/>
      </div>
        
      </div>
    </div>
  </div>

  <div className='divider2 mt-3'></div>
  <p className='text-center m-2'>All Rights Reserved © 2023 Execution Tech</p>

</div>

    

</div>
  
</div>
  </>
}
