import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import { Suspense, lazy } from "react";
import Footer from './components/Footer/Footer';
import Loading from './components/Layout/Loading';
import Layout from "./components/Layout/Layout";
const Home = lazy(() => import("./components/Home/Home"));
const About = lazy(() => import("./components/About/About"));
const Services = lazy(() => import("./components/Services/Services"));
const Contact = lazy(() => import("./components/Contact/Contact"));
const Products = lazy(() => import("./components/Products/Products"));
const Consultation = lazy(() =>
  import("./components/Consultation/Consultation")
);
const Outsourcing = lazy(() =>
  import("./components/Outsourcing/Outsourcing")
);
const SoftwareDev = lazy(() =>
  import("./components/SoftwareDev/SoftwareDev")
);

function App() {

  return <>
  
  <Router>
    <Routes>
      <Route path="/" element={<Layout />}>
      
      <Route path='' element={
      <Suspense fallback={<Loading />}>
      <Home />
      </Suspense>
       } />

        <Route path='home' element={
        <Suspense fallback={<Loading />}>
        <Home />
        </Suspense>
        } />
       
        <Route path="about" element={
        <Suspense fallback={<Loading />}>
        <About />
        </Suspense>
          } />
          
        <Route path="contact" element={
        <Suspense fallback={<Loading />}>
        <Contact/>
        </Suspense>} />

        <Route path="services" element={
        <Suspense fallback={<Loading />}>
        <Services/>
        </Suspense>} />

        <Route path="products" element={
        <Suspense fallback={<Loading />}>
        <Products/>
        </Suspense>} />

        <Route path="consultation" element={
        <Suspense fallback={<Loading />}>
        <Consultation/>
        </Suspense>} />
    
        <Route path="outsourcing" element={
        <Suspense fallback={<Loading />}>
        <Outsourcing/>
      </Suspense>} />

        <Route path="softwareDev" element={
        <Suspense fallback={<Loading />}>
    <SoftwareDev/>
    </Suspense>} />

      </Route>
    </Routes>
  </Router>
  
  </>
}

export default App;
