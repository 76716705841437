import { Link } from "react-router-dom";
import style from './Contact.module.css'

const EmailLink = ({ email }) => {
  const gmailLink = `mailto:${email}`;

  return (
    <Link to={gmailLink}  className={style.contact} target="_blank" >
      {email}
    </Link>
  );
};
export default EmailLink;