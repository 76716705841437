import React from "react"
import { Grid } from "react-loader-spinner";

const Loading= () => {

    return <>
    <div className="Loading">
    <Grid
    visible={true}
    height="60"
    width="60"
    color="#b32a60"
    ariaLabel="grid-loading"
    radius="12.5"
    wrapperStyle={{}}
    wrapperClass="grid-wrapper"
    />

        </div>

    </>
}
export default Loading;