import React, { useEffect, useState } from 'react'
import { Link, NavLink, useLocation } from 'react-router-dom'
import style from './Navbar.module.css'
import logo from'../../assets/logo2.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone } from '@fortawesome/free-solid-svg-icons'
import PhoneNumberLink from '../Contact/phonenumber'

export default function Navbar() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [mobileNavOpen, setMobileNavOpen] = useState(false);

  
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []); 

  const closeMobileNav = () => {
    setMobileNavOpen(false);
  };

  const location = useLocation()
  const isHomePage = ['/', '/home'].includes(location.pathname);

  return <>
  
  <nav className={`navbar navbar-expand-lg navbar-light ${
      windowWidth <= 992 ? "mobileNavbar" : (isHomePage ? "transparentNavbar" : "defaultNavbar")}`}>

  <div className="Navcontainer container">
        <Link className="navbar-brand" to='home'>

          <img className="logo" src={logo} alt='executiontech' /> 
          
        </Link>

    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"    onClick={() => setMobileNavOpen(!mobileNavOpen)}>
      <span className="navbar-toggler-icon"></span>
    </button>

    <div className={`collapse navbar-collapse  ${mobileNavOpen ? 'show' : ''}`} id="navbarSupportedContent">
      <ul className="navbar-nav  mb-lg-0 ">
        <li className="nav-item">
          <NavLink className="nav-link" onClick={closeMobileNav} to='home'>Home</NavLink>
        </li>
        <li className="nav-item">
          <NavLink className="nav-link" onClick={closeMobileNav} to='about'>About</NavLink>
        </li>

        <li className="nav-item">
        <NavLink className="nav-link" onClick={closeMobileNav} to='services'>Services</NavLink>
      </li>

      <li className="nav-item">
      <NavLink className="nav-link" onClick={closeMobileNav} to='products'>Products</NavLink>
    </li>
    
    <li className="nav-item">
    <NavLink className="nav-link" onClick={closeMobileNav} to='contact'>Contact</NavLink>
  </li>
      </ul>

      <div className='phoneNo'>
   <span className='me-2'> <FontAwesomeIcon icon={faPhone}/></span> 
    <span> <PhoneNumberLink isDone={false}  phoneNumber={'+201003718640 '}/>  /  <PhoneNumberLink   phoneNumber={'+201097709762'}/> </span>
    </div>

    </div>


  </div>
</nav>
  

  </>
}