import { Link } from "react-router-dom";
import style from './Contact.module.css'
import classNames from "classnames";
import { useState } from "react";

const PhoneNumberLink = ({ phoneNumber, isDone }) => {

  const [isHovered, setIsHovered] = useState(false);

  const handlehover = () => {
    setIsHovered(true);
  };

  const handlewithouthover = () => {
    setIsHovered(false);
  };
    const whatsappLink = `https://wa.me/${phoneNumber}`;
  
    return (
      <Link  to={whatsappLink} target="_blank" 
      className={` ${isDone ? style.contact : style.numbers}`}
      onMouseEnter={isDone ? handlehover : undefined}
      onMouseLeave={isDone ? handlewithouthover : undefined}
      >
        {phoneNumber} 
    
      </Link>
    );
  };
  
  export default PhoneNumberLink